/**
 * @module AddressCheckHero
 * @description
 */
import { useContext, useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { FuelContext } from '@/providers/fuel-provider';
import ServiceCheckForm from '@/blocks/ServiceCheckForm';
import state from '@/js/config/state';
import styles from './HeroAddressCheck.module.css';

const PhoneButton = dynamic( () => import( '@/blocks/Buttons/PhoneButton' ) );
const position = 'HERO ADDRESS CHECK';

/**
 * @function Component
 * @description Hero that displays headline and predictive service check
 */
const Component = ( { ...props } ) => {
  const {
    className = '',
    theme = 'dark',
    headline,
    horizonHeadline,
    background,
    subHeadline,
    useSelectAreasLegal = <>Frontier<sup>&reg;</sup> Fiber Internet available in select&nbsp;areas.</>,
    useNFLSTLegal,
    customStyles,
    showFormLead = false
  } = props;
  const { phoneHeavy } = useContext( FuelContext );
  const backgroundPath = `${ process.env.imagePath }/sections/hero/${ background }`;
  const [ hideDesktopBackground, setHideDesktopBackground ] = useState( true );

  const determineViewport = () => {
    if ( ! state.is_mobile ) {
      return setHideDesktopBackground( false );
    }

    return setHideDesktopBackground( true );
  };

  useEffect( () => {
    determineViewport();
    document.addEventListener( 'redventures/resize_executed', determineViewport );
  }, [] );

  return (
    <section className={ `component ${ styles.component } ${ className } ${ theme && `u-theme-${ theme } ` } ${ customStyles && customStyles.component }` } style={ { backgroundImage: hideDesktopBackground ? 'url()' : `url(${ backgroundPath }--large.jpg )` } }>
      <div className={ `l-container ${ styles.component__wrapper } ${ customStyles && customStyles.component__wrapper }` }>
        <div className="g-row">
          <div className={ `g-col g-col--two-thirds ${ customStyles && customStyles.component__layout }` }>
            <div className={ `${ styles.component__contentWrapper }` }>
            { horizonHeadline ?
                <h2 className={ styles.component__headlineWrapper }>
                  <span className={ `h1 component__horizonHeadline ${ styles.component__horizonHeadline }` }>{ horizonHeadline[ 0 ] }</span>
                  <span className={ `h1 component__horizonHeadline ${ styles.component__horizonHeadline }` }>{ horizonHeadline[ 1 ] }</span>
                  <span className={ `h1 component__horizonHeadline ${ styles.component__horizonHeadline }` }>{ horizonHeadline[ 2 ] }</span>
                </h2> :
                <h2 className={ `${ styles.component__headline } h1 ${ customStyles && customStyles.component__headline }` }>{ headline }</h2>
              }

              <div className={ customStyles && customStyles.component__ctaContainer }>
              { phoneHeavy ?
                <>
                  <h3 className='h4'>Call now to speak with an expert about the plans available in your area.</h3>

                  <PhoneButton
                    position={ position }
                  />

                  { useSelectAreasLegal && <p className={ `legal ${ styles.component__selectAreasLegal } ` }>{ useSelectAreasLegal }</p> }
                </> :
                <>
                  { subHeadline && <h3 className={ `h4 ${ styles.component__subHeadline } ${ customStyles && customStyles.component__subHeadline }` }>{ subHeadline }</h3>}
                  { showFormLead &&
                    <p className={ `h6 ${ styles.component__formLead }` }>
                      <span className={ `icon icon-location-marker ${ styles.component__icon }` } />
                      <span>Enter your address to view plans in your area.</span>
                    </p>
                  }

                  <div className={ styles.component__formWrapper }>
                    <ServiceCheckForm />
                  </div>

                 { useSelectAreasLegal && <p className={ `legal ${ styles.component__selectAreasLegal } ` }>{ useSelectAreasLegal }</p> }
                 { useNFLSTLegal && <p className={ `legal ${ styles.component__NFLSTLegal } ` }>{ useNFLSTLegal }</p>}
                </>
              }
              </div>
            </div>
          </div>
        </div>
        <div className={ `${ styles.component__mobileBackground } ${ customStyles && customStyles.component__mobileBackground }` } style={ { backgroundImage: `url( ${ backgroundPath }.jpg )` } } />
      </div>
    </section>
  );
};

Component.defaultProps = {
  background: 'lady-checking-frontier-pricing'
};

export default Component;
